import * as React from 'react'

import i18next from 'i18next'

import {
  I18nProvider as DesignSystemI18nProvider,
  Locale,
} from '@enechain/ecloud-designsystem'

import { Language } from '~/gen/proto/bff/v1/model_pb'
import { useLoginUser } from '~/hooks/useLoginUser'

export const I18nProvider = ({
  children,
}: {
  children: React.ReactNode
}): React.ReactNode => {
  const me = useLoginUser()
  React.useEffect(() => {
    void i18next.changeLanguage(getLngString(me.language))
  }, [me.language])
  return (
    <DesignSystemI18nProvider locale={getLngString(me.language)}>
      {children}
    </DesignSystemI18nProvider>
  )
}

export function getLngString(language: Language): Locale {
  switch (language) {
    case Language.ENGLISH:
      return 'en'
    case Language.JAPANESE:
      return 'ja'
    default:
      console.warn('Invalid Language Setting. Fallbacked to Japanese')
      return 'ja'
  }
}
